export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/shops`,
  async getShops() {
    return await $fetch(`${this.apiURL}`, { headers: { Authorization: useToken().value } });
  },
  async getShop(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveShop(shop) {
    return await $fetch(this.apiURL, {
      method: shop.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...shop },
    });
  },
  async saveShopsOrder(order) {
    return await $fetch(`${this.apiURL}/save-order`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order },
    });
  },
  async deleteShop(shop) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: shop.id },
    });
  },
  async uploadImage(shop, image) {
    const formData = new FormData();
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/${shop.id}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(shop) {
    return await $fetch(`${this.apiURL}/${shop.id}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async addFilter(shopId, filterId, type) {
    return await $fetch(`${this.apiURL}/add-filter`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { shop_id: shopId, filter_id: filterId, type },
    });
  },
  async addEvent(shop, eventId) {
    return await $fetch(`${this.apiURL}/${shop.id}/add-event`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { event_id: eventId },
    });
  },
  async saveEvent(shopEvent) {
    return await $fetch(`${this.apiURL}/${shopEvent.id}/save-event`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: shopEvent,
    });
  },
  async removeEvent(shop, shopEventId) {
    return await $fetch(`${this.apiURL}/${shop.id}/remove-event`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { shop_event_id: shopEventId },
    });
  },
  async getOwnShops() {
    return await $fetch(`${this.apiURL}/own`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async addUser(shop, userId) {
    return await $fetch(`${this.apiURL}/${shop.id}/add-user`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: userId },
    });
  },
  async removeUser(shop, userId) {
    return await $fetch(`${this.apiURL}/${shop.id}/remove-user`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: userId },
    });
  },
  async getShopUrl() {
    return await $fetch(`${useRuntimeConfig().public.apiURL}/url`, {
      method: "GET",
    });
  },
});
