export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/users`,
  apiURLUserGroups: `${useRuntimeConfig().public.apiURL}/user-groups`,
  apiURLUserHistory: `${useRuntimeConfig().public.apiURL}/user-history`,
  async getUsers() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getUser(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async createUser(user, userAddress, userContact) {
    user.user_address = { ...userAddress };
    user.user_contact = { ...userContact };
    return await $fetch(this.apiURL, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...user },
    });
  },

  async getAttributes(userId) {
    return await $fetch(`${this.apiURL}/${userId}/attributes`, { headers: { Authorization: useToken().value } });
  },
  async createOrUpdateAttribute(body) {
    if (body.id === "new") delete body.id;
    return await $fetch(`${this.apiURL}/${body.userId}/attributes`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },

  async updateUserEmail(body) {
    return await $fetch(`${this.apiURL}/changeEmail`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async deleteUserAttributes(userId, id) {
    return await $fetch(`${this.apiURL}/${userId}/attributes/${id}`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
    });
  },

  async updateUser(user) {
    return await $fetch(this.apiURL, {
      method: "PUT",
      headers: { Authorization: useToken().value },
      body: { ...user },
    });
  },
  async deleteUser(user) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: user.id },
    });
  },
  async createUserPayment(userPayment) {
    return await $fetch(`${this.apiURL}/create-payment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...userPayment },
    });
  },
  async saveUserPayment(userPayment) {
    return await $fetch(`${this.apiURL}/save-payment/${userPayment.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...userPayment },
    });
  },
  async deleteUserPayment(userPayment) {
    return await $fetch(`${this.apiURL}/delete-payment/${userPayment.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async saveUserAddress(userAddress) {
    return await $fetch(`${this.apiURL}/save-address/${userAddress.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...userAddress },
    });
  },
  async deleteUserAddress(userAddress) {
    return await $fetch(`${this.apiURL}/delete-address/${userAddress.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async saveUserContact(userContact) {
    return await $fetch(`${this.apiURL}/save-contact/${userContact.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...userContact },
    });
  },
  async deleteUserContact(userContact) {
    return await $fetch(`${this.apiURL}/delete-contact/${userContact.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async getUserHistory(userHistory) {
    return await $fetch(`${this.apiURLUserHistory}/${userHistory}`, { headers: { Authorization: useToken().value } });
  },
  async saveUserHistory(userHistory) {
    return await $fetch(`${this.apiURL}/save-history/${userHistory.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...userHistory },
    });
  },
  async deleteUserHistory(userHistory) {
    return await $fetch(`${this.apiURL}/delete-history/${userHistory.id}`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async getGroups() {
    return await $fetch(this.apiURLUserGroups, { headers: { Authorization: useToken().value } });
  },
  async getGroup(id) {
    return await $fetch(`${this.apiURLUserGroups}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveGroup(group) {
    return await $fetch(this.apiURLUserGroups, {
      method: group.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...group },
    });
  },
  async deleteGroup(group) {
    return await $fetch(this.apiURLUserGroups, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: group.id },
    });
  },
});
