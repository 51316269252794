import { defineStore } from "pinia";
import { ORDER_TYPE_RESERVATION } from "~/constants";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    permissions: [],
    admin: null,
  }),
  actions: {
    async fetch() {
      const { data: admin } = useAuth();
      this.admin = admin.value;

      admin.value.roles.forEach((_role) => {
        this.permissions = this.permissions.concat(_role.permissions);
      });
    },
    hasPermission(permissions) {
      const isSuperAdmin = this.permissions.some(_permission => _permission.name === "Super Admin");
      if (isSuperAdmin) return true;

      // CUSTOM: Reservierung Bearbeiten darf nur Ersteller
      const route = useRoute();
      if (route.name === "orders-id") {
        const order = useState("order");
        if (order.value?.type === ORDER_TYPE_RESERVATION && order.value?.created_from !== this.admin.id && route.query.edit !== "1") {
          return false;
        }
      }

      return this.permissions.some(_permission => permissions.includes(_permission.name));
    },
  },
});
