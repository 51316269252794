export default defineNuxtPlugin(async (nuxtApp) => {
  const clientId = useCookie("client");

  nuxtApp.filters = {
    shop: {
      title: "Shops",
      type: "filter_shop",
      path: "/shops",
      select: "shop left join admin_user on admin_user.id = shop.created_from where shop.deleted_at is null",
      columns: [
        { title: "id", data: "shop.id" },
        { title: "Name", data: "shop.name" },
        { title: "Code", data: "shop.code" },
        { title: "Aktiv", data: "shop.active" },
      ],
      options: [
        { type: "number", title: "id", data: "shop.id" },
        { type: "text", title: "Name", data: "shop.name" },
        { type: "bool", title: "Aktiv", data: "shop.active" },
        { type: "text", title: "Code", data: "shop.code" },
        { type: "text", title: "Info", data: "shop.info" },
        { type: "number", title: "Provision", data: "shop.provision" },
        { type: "date", title: "Erstellt am", data: "shop.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
      ],
      standard_options: [
        {
          type: "select",
          title: "Aktiv",
          data: "shop.active",
          value: 1,
          options: [ { id: 1, title: "Aktiv" } ],
        },
      ],
      resource: null,
    },
    client: {
      title: "Mandanten",
      type: "filter_client",
      path: "/clients",
      select: "client left join admin_user on admin_user.id = client.created_from where client.deleted_at is null",
      columns: [
        { title: "id", data: "client.id" },
        { title: "Name", data: "client.name" },
        { title: "Nummer", data: "client.number" },
        { title: "Aktiv", data: "client.active" },
      ],
      options: [
        { type: "number", title: "id", data: "client.id" },
        { type: "text", title: "Name", data: "client.name" },
        { type: "text", title: "Nummer", data: "client.number" },
        { type: "bool", title: "Aktiv", data: "client.active" },
        { type: "date", title: "Erstellt am", data: "client.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "text", title: "Zahlungsziel", data: "client.due_date" },
        { type: "text", title: "Facebook", data: "client.facebook_url" },
        { type: "text", title: "Instagramm", data: "client.instagram_url" },
        { type: "text", title: "Youtube", data: "client.youtube_url" },
        { type: "text", title: "Email Absendeadresse", data: "client.email_from" },
        { type: "number", title: "Entwurf Minuten", data: "client.drafts_delete_minutes" },
        { type: "bool", title: "Gutschein Storno erlaubt", data: "client.allow_voucher_storno" },
        { type: "number", title: "Gutschein Sperrtage", data: "client.voucher_days" },
      ],
      standard_options: [
        {
          type: "select",
          title: "Aktiv",
          data: "client.active",
          value: 1,
          options: [ { id: 1, title: "Aktiv" } ],
        },
      ],
      resource: null,
    },
    orders: {
      title: "Buchungen",
      type: "filter_orders",
      path: "/orders",
      group_by: "`order`.id",
      order: 0,
      order_dir: "desc",
      select: "`order` "
        + "left join admin_user on admin_user.id = `order`.created_from "
        + "left join client on client.id = `order`.client_id "
        + "left join shop on shop.id = `order`.shop_id "
        + "left join shipping_method on shipping_method.id = `order`.shipping_id "
        + "left join user on user.id = `order`.user_id "
        + "left join `order` as parent_order on parent_order.id = `order`.parent_id "
        + "left join user_address on user_address.user_id = user.id "
        + "left join payment on payment.id = `order`.payment_id "
        + "left join order_item on order_item.order_id = `order`.id "
        + "left join event_date_has_product on event_date_has_product.id = order_item.event_date_product_id "
        + "left join event_date on event_date.id = event_date_has_product.event_date_id "
        + " where `order`.deleted_at is null and order_item.deleted_at is null",
      columns: [
        { title: "id", data: "`order`.id" },
        { title: "Buchungsdatum", data: "`order`.published_at" },
        { title: "Nummer", data: "CONCAT_WS(' - ', parent_order.order_number, `order`.order_number)" },
        {
          title: "Termin",
          data: "GROUP_CONCAT(DISTINCT"
            + "  CASE \n"
            + "    WHEN TRIM(event_date.name) != '' AND event_date.date IS NOT NULL THEN CONCAT_WS(' ', event_date.name, DATE_FORMAT(event_date.date, '%d.%m.%y %H:%i'))\n"
            + "    ELSE NULL \n"
            + "  END\n"
            + "  SEPARATOR '\\n')\n",
        },
        { title: "Shop", data: "shop.name" },
        { title: "Mandant", data: "client.name" },
        { title: "Zahlungsart", data: "payment.name" },
        { title: "Versandart", data: "shipping_method.name" },
        { title: "Kunde", data: "CONCAT_WS(' ', user_address.firstname, user_address.lastname)" },
        { title: "Preis", data: "order.total_price" },
        { title: "Erstellt von", data: "admin_user.email" },
      ],
      options: [
        { type: "number", title: "id", data: "`order`.id" },
        { type: "date", title: "Buchungsdatum", data: "`order`.published_at" },
        { type: "text", title: "Nummer", data: "`order`.order_number" },
        {
          type: "text",
          title: "Termin",
          data: "GROUP_CONCAT(DISTINCT"
              + "  CASE \n"
              + "    WHEN TRIM(event_date.name) != '' AND event_date.date IS NOT NULL THEN CONCAT_WS(' ', event_date.name, DATE_FORMAT(event_date.date, '%d.%m.%y %H:%i'))\n"
              + "    ELSE NULL \n"
              + "  END\n"
              + "  SEPARATOR '\\n')\n",
          where: "`event_date`.name",
        },
        { type: "text", title: "Shop", data: "shop.name" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "number", title: "Gesamtpreis", data: "order.total_price" },
        { type: "text", title: "Zahlungsart", data: "payment.name" },
        { type: "text", title: "Versandart", data: "shipping_method.name" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "text", title: "Kunde", data: "CONCAT_WS(' ', user_address.firstname, user_address.lastname)" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "client.id", value: clientId.value },
        {
          type: "select",
          title: "Typ",
          data: "order.type",
          value: "1",
          options: [
            { id: "0", title: "Entwurf" },
            { id: "2", title: "Reservierung" },
            { id: "1", title: "Buchungen" },
          ],
        },
        {
          type: "select",
          title: "Status",
          data: "order.status",
          value: null,
          options: [
            { id: "Offen", title: "Offen" },
            { id: "In Bearbeitung", title: "In Bearbeitung" },
            { id: "In Klärung", title: "In Klärung" },
            { id: "Erledigt", title: "Erledigt" },
          ],
        },
        { type: "date", title: "Termindatum", data: "CAST(event_date.date as Date)", value: null },
        { type: "date", title: "Buchung von", data: "CAST(order.published_at as Date)", value: null, operator: ">=" },
        { type: "date", title: "Buchung bis", data: "CAST(order.published_at as Date)", value: null, operator: "<=" },
        { type: "checkbox", title: "enthält Stornierung", data: "order.has_storno", value: null },
      ],
      resource: null,
    },
    task_orders: {
      title: "Buchungen",
      type: "filter_task_orders",
      group_by: "`order`.id",
      order: 0,
      order_dir: "desc",
      select: "`order` "
        + "left join admin_user on admin_user.id = `order`.created_from "
        + "left join client on client.id = `order`.client_id "
        + "left join shop on shop.id = `order`.shop_id "
        + "left join shipping_method on shipping_method.id = `order`.shipping_id "
        + "left join user on user.id = `order`.user_id "
        + "left join user_address on user_address.user_id = user.id "
        + "left join payment on payment.id = `order`.payment_id "
        + "left join order_item on order_item.order_id = `order`.id "
        + "left join order_item_article on order_item.id = order_item_article.order_item_id "
        + "left join event_date_has_product on event_date_has_product.id = order_item.event_date_product_id "
        + "left join event_date on event_date.id = event_date_has_product.event_date_id "
        + " where `order`.deleted_at is null",
      columns: [
        { title: "id", data: "`order`.id" },
        { title: "Buchungsdatum", data: "`order`.published_at" },
        { title: "Nummer", data: "`order`.order_number" },
        {
          title: "Termin",
          data: "GROUP_CONCAT(DISTINCT"
            + "  CASE \n"
            + "    WHEN TRIM(event_date.name) != '' AND event_date.date IS NOT NULL THEN CONCAT_WS(' ', event_date.name, DATE_FORMAT(event_date.date, '%d.%m.%y %H:%i'))\n"
            + "    ELSE NULL \n"
            + "  END\n"
            + "  SEPARATOR '\\n')\n",
        },
        { title: "Shop", data: "shop.name" },
        { title: "Mandant", data: "client.name" },
        { title: "Zahlungsart", data: "payment.name" },
        { title: "Versandart UJUEBNAIOFDIO", data: "shipping_method.name" },
        { title: "Kunde", data: "CONCAT_WS(' ', order.billing_firstname, order.billing_lastname)" },
        { title: "Preis", data: "order.total_price" },
      ],
      options: [
        { type: "number", title: "id", data: "`order`.id" },
        { type: "date", title: "Buchungsdatum", data: "`order`.published_at" },
        { type: "text", title: "Nummer", data: "`order`.order_number" },
        { type: "text", title: "Termin", data: "GROUP_CONCAT(DISTINCT CONCAT_WS(' ', event_date.name, DATE_FORMAT(event_date.date, '%d.%m.%y %H:%i')) SEPARATOR '\n')", where: "`event_date`.name" },
        { type: "text", title: "Shop", data: "shop.name" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "number", title: "Gesamtpreis", data: "order.total_price" },
        { type: "text", title: "Zahlungsart", data: "payment.name" },
        { type: "text", title: "Versandart", data: "shipping_method.name" },
        { type: "text", title: "Kunde", data: "CONCAT_WS(' ', order.billing_firstname, order.billing_lastname)" },
        { type: "text", title: "Produkt Art", data: "order_item_article.article_number" },
        { type: "text", title: "Produkt Name", data: "order_item_article.name" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "client.id", value: clientId.value },
        {
          type: "select",
          title: "Typ",
          data: "order.type",
          value: "1",
          options: [
            { id: "0", title: "Draft" },
            { id: "2", title: "Reservierung" },
            { id: "1", title: "Buchungen" },
          ],
        },
        { type: "date", title: "Termindatum", data: "CAST(event_date.date as Date)", value: null },
      ],
      resource: null,
    },
    articles: {
      title: "Artikel",
      type: "filter_articles",
      path: "/articles",
      select: "article "
        + "left join admin_user on admin_user.id = article.created_from "
        + "left join article_group on article_group.id = article.article_group_id "
        + "left join tax on tax.id = article.tax_id "
        + "left join sales_group on sales_group.id = article.sales_group_id "
        + "where article.deleted_at is null",
      columns: [
        { title: "id", data: "article.id" },
        { title: "Name", data: "article.name" },
        { title: "Nummer", data: "article.article_number" },
        { title: "Artikelgruppe", data: "article_group.name" },
      ],
      options: [
        { type: "number", title: "id", data: "article.id" },
        { type: "text", title: "Name", data: "article.name" },
        { type: "bool", title: "Aktiv", data: "article.active" },
        { type: "text", title: "Artikelnummer", data: "article.article_number" },
        { type: "text", title: "Artikelgruppe", data: "article_group.name" },
        { type: "text", title: "Besteuerungsart", data: "tax.name" },
        { type: "text", title: "Erlösgruppe", data: "sales_group.name" },
        { type: "bool", title: "Provisionierbar", data: "article.provisionable" },
        { type: "bool", title: "Rabattierbar", data: "article.discountable" },
        { type: "date", title: "Erstellt am", data: "article.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
      ],
      resource: null,
    },
    events: {
      title: "Veranstaltungsreihen",
      type: "filter_events",
      path: "/events",
      select: "event "
        + "left join admin_user on admin_user.id = event.created_from "
        + "where event.deleted_at is null",
      columns: [
        { title: "id", data: "event.id" },
        { title: "Name", data: "event.name" },
        { title: "Online verfügbar", data: "event.is_online_available" },
      ],
      options: [
        { type: "text", title: "Name", data: "event.name" },
        { type: "text", title: "Beschreibung", data: "event.description" },
        { type: "text", title: "Video URL", data: "event.video_url" },
        { type: "bool", title: "Aktiv", data: "event.active" },
        { type: "bool", title: "Online verfügbar", data: "event.is_online_available" },
        { type: "date", title: "Online verfübar ab", data: "event.online_available_from" },
        { type: "date", title: "Erstellt am", data: "event.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
      ],
      standard_options: [
        {
          type: "select",
          title: "Aktiv",
          data: "event.active",
          value: 1,
          options: [ { id: 1, title: "Aktiv" } ],
        },
      ],
      resource: null,
    },
    event_dates: {
      title: "Termine",
      type: "filter_event_dates",
      path: "/event-dates",
      order: 1,
      order_dir: "desc",
      select: "event_date "
        + "left join admin_user on admin_user.id = event_date.created_from "
        + "left join client on client.id = event_date.client_id "
        + "left join price_list on price_list.id = event_date.price_list_id "
        + "where event_date.deleted_at is null",
      columns: [
        { title: "id", data: "event_date.id" },
        { title: "Datum", data: "event_date.date" },
        { title: "Veranstaltung", data: "event_date.name" },
        { title: "Mandant", data: "client.name" },
        { title: "Aktiv", data: "event_date.active" },
        { title: "Online verfügbar", data: "event_date.is_online_available" },
      ],
      edit_url: "/event-dates/edit",
      options: [
        { type: "number", title: "id", data: "event_date.id" },
        { type: "date", title: "Datum", data: "event_date.date" },
        { type: "text", title: "Veranstaltung", data: "event_date.name" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "bool", title: "Aktiv", data: "event_date.active" },
        { type: "bool", title: "Online verfügbar", data: "event_date.is_online_available" },
        { type: "date", title: "Erstellt am", data: "event_date.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "date", title: "Abgesagt am", data: "event_date.canceled_at" },
        { type: "text", title: "Info", data: "event_date.info" },
        { type: "text", title: "Preisliste", data: "price_list.title" },
        { type: "number", title: "Max Buchungen", data: "event_date.max_tickets" },
        { type: "number", title: "Online Shop Kontingent", data: "event_date.plan_quota" },
        { type: "date", title: "Online verfügbar ab", data: "event_date.online_available_from" },
        { type: "bool", title: "Gesperrt", data: "event_date.locked" },
        { type: "bool", title: "Gesperrt Info", data: "event_date.locked_info" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "event_date.client_id", value: clientId.value },
        {
          type: "select",
          title: "Status",
          data: "CASE WHEN event_date.canceled_at IS NULL THEN 0 ELSE 1 END",
          value: "0",
          options: [ { id: "0", title: "Aktive Termine" }, { id: "1", title: "Abgesagte Termine" } ],
        },
      ],
      resource: null,
    },
    products: {
      title: "Produkte",
      type: "filter_products",
      path: "/products",
      select: "product left join product_group on product_group.id = product.product_group_id"
        + " left join admin_user on admin_user.id = product.created_from"
        + " left join client on client.id = product.client_id"
        + " where product.deleted_at is null",
      columns: [
        { title: "id", data: "product.id" },
        { title: "Name", data: "product.name" },
        { title: "Produktnummer", data: "product.product_number" },
        { title: "Produktgruppe", data: "product_group.name" },
      ],
      options: [
        { type: "number", title: "id", data: "product.id" },
        { type: "text", title: "Name", data: "product.name" },
        { type: "text", title: "Produktnummer", data: "product.product_number" },
        { type: "text", title: "Produktgruppe", data: "product_group.name" },
        { type: "bool", title: "Aktiv", data: "product.active" },
        { type: "bool", title: "Online verfügbar", data: "product.online_available" },
        { type: "text", title: "Typ", data: "CASE WHEN product.type = 1 THEN 'Arrangement' WHEN product.type = 2 THEN 'Freie Produkte' WHEN product.type = 3 THEN 'Gutscheine' ELSE '' END" },
        { type: "text", title: "Info", data: "product.info" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "number", title: "Versandkosten", data: "product.delivery_price" },
        { type: "number", title: "Stunden bis VA verfügbar", data: "product.hours_before_buyable" },
        { type: "number", title: "Anzahl Platzbelegung", data: "product.block_amount" },
        { type: "date", title: "Aktiv für Termine ab", data: "product.active_from" },
        { type: "date", title: "Aktiv für Termine bis", data: "product.active_to" },
        { type: "date", title: "Erstellt am", data: "product.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "bool", title: "Name änderbar", data: "product.is_name_changeable" },
        { type: "bool", title: "keine Mehrfachbuchung Produktgruppe", data: "product.is_unique_upgrade" },
        { type: "bool", title: "Preis wählbar", data: "product.is_price_input" },
        { type: "bool", title: "Widmungstext pflegbar", data: "product.is_voucher_text" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "product.client_id", value: clientId.value },
        {
          type: "select",
          title: "Aktiv",
          data: "product.active",
          value: 1,
          options: [ { id: 1, title: "Aktiv" } ],
        },
        {
          type: "select",
          title: "Varianten",
          data: "CASE WHEN product.parent_id IS NULL THEN 0 ELSE 1 END",
          value: "0",
          options: [ { id: "0", title: "Standard" }, { id: "1", title: "Varianten" } ],
        },
        {
          type: "select",
          title: "Typ",
          data: "product.type",
          value: null,
          options: [ { id: 1, title: "Arrangement" }, { id: 2, title: "Freie Produkte" }, { id: 3, title: "Gutschein" } ],
        },
      ],
      resource: null,
    },
    vouchers: {
      title: "Gutscheine",
      type: "filter_vouchers",
      path: "/vouchers",
      order: 0,
      order_dir: "desc",
      select: "voucher"
        + " left join order_item on order_item.id = voucher.order_item_id"
        + " left join order_item used_order_item on used_order_item.voucher_id = voucher.id and used_order_item.deleted_at is null"
        + " left join `order` on `order`.id = order_item.order_id"
        + " where voucher.deleted_at is null"
        + " group by voucher.id",
      columns: [
        { title: "id", data: "voucher.id" },
        { title: "Erstellt am", data: "voucher.created_at" },
        { title: "Name", data: "voucher.name" },
        { title: "Code", data: "voucher.code" },
        { title: "Wert", data: "voucher.value" },
        { title: "Eingelöst", data: "CASE WHEN  used_order_item.id is not null THEN true ELSE false END" },
        { title: "Gesperrt", data: "voucher.is_locked" },
      ],
      options: [
        { type: "number", title: "id", data: "voucher.id" },
        { type: "text", title: "Name", data: "voucher.name" },
        { type: "text", title: "Code", data: "voucher.code" },
        { type: "text", title: "Wert", data: "voucher.value" },
        { type: "bool", title: "Eingelöst", data: "CASE WHEN voucher.used_extern is not null or used_order_item.id is not null THEN true ELSE false END" },
        { type: "bool", title: "Gesperrt", data: "voucher.is_locked" },
        { type: "date", title: "Erstellt am", data: "voucher.created_at" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "`order`.client_id", value: clientId.value },
      ],
      resource: null,
    },
    rooms: {
      title: "Räume",
      type: "filter_rooms",
      path: "/rooms",
      select: "room "
        + "left join admin_user on admin_user.id = room.created_from "
        + "left join client on client.id = room.client_id "
        + "where room.deleted_at is null",
      columns: [
        { title: "id", data: "room.id" },
        { title: "Name", data: "room.name" },
        { title: "Mandant", data: "client.name" },
      ],
      options: [
        { type: "number", title: "id", data: "room.id" },
        { type: "text", title: "Name", data: "room.name" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "bool", title: "Aktiv", data: "room.active" },
        { type: "date", title: "Erstellt am", data: "room.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "room.client_id", value: clientId.value },
      ],
      resource: null,
    },
    plans: {
      title: "Saalpläne",
      type: "filter_plans",
      path: "/plans",
      select: "plan "
        + "left join admin_user on admin_user.id = plan.created_from "
        + "left join client on client.id = plan.client_id "
        + "where plan.deleted_at is null",
      columns: [
        { title: "id", data: "plan.id" },
        { title: "Name", data: "plan.name" },
        { title: "Mandant", data: "client.name" },
      ],
      options: [
        { type: "number", title: "id", data: "plan.id" },
        { type: "text", title: "Name", data: "plan.name" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "date", title: "Erstellt am", data: "plan.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "plan.client_id", value: clientId.value },
      ],
      resource: null,
    },
    users: {
      title: "Kunden",
      type: "filter_users",
      path: "/users",
      select: "user left join user_address on user_address.user_id = user.id left join user_payment on user_payment.user_id = user.id left join user_contact on user_contact.user_id = user.id left join admin_user on admin_user.id = user.created_from where user.deleted_at is null",
      group_by: "user.id",
      columns: [
        { title: "id", data: "user.id" },
        { title: "Vorname", data: "user_address.firstname" },
        { title: "Nachname", data: "user_address.lastname" },
        { title: "Strasse", data: "user_address.street" },
        { title: "Stadt", data: "user_address.city" },
        { title: "PLZ", data: "user_address.zip" },
      ],
      options: [
        { type: "number", title: "id", data: "user.id" },
        { type: "text", title: "Email", data: "user.email" },
        { type: "text", title: "Telefon", data: "user.phone" },
        { type: "text", title: "Typ", data: "user.type" },
        { type: "date", title: "Geburtstag", data: "user.birthdate" },
        { type: "text", title: "Firma", data: "user_address.company" },
        { type: "text", title: "Vorname", data: "user_address.firstname" },
        { type: "text", title: "Nachname", data: "user_address.lastname" },
        { type: "text", title: "Anrede", data: "user_address.salutation" },
        { type: "text", title: "Titel", data: "user_address.title" },
        { type: "text", title: "Adresskommentar", data: "user_address.comment" },
        { type: "text", title: "Strasse", data: "user_address.street" },
        { type: "text", title: "Hausnummer", data: "user_address.housenumber" },
        { type: "text", title: "PLZ", data: "user_address.zip" },
        { type: "text", title: "Stadt", data: "user_address.city" },
        { type: "text", title: "Land", data: "user_address.country" },
        { type: "text", title: "Kontakt", data: "user_contact.content" },
        { type: "text", title: "Iban", data: "user_payment.iban" },
        { type: "text", title: "Konto", data: "user_payment.account" },
        { type: "text", title: "Branche", data: "user.branch" },
        { type: "text", title: "Mitarbeiteranzahl", data: "user.employee_amount" },
        { type: "text", title: "Abteilung", data: "user.section" },
        { type: "text", title: "Funktion", data: "user.role" },
        { type: "date", title: "Erstellt am", data: "user.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "number", title: "Fehlerhafte Logins", data: "user.failed_logins" },
        { type: "date", title: "Gesperrt seit", data: "user.locked_at" },
      ],
      resource: null,
    },
    event_users: {
      type: "filter_event_users",
      select: "user left join user_address on user_address.user_id = user.id left join user_payment on user_payment.user_id = user.id left join user_contact on user_contact.user_id = user.id left join admin_user on admin_user.id = user.created_from where user.deleted_at is null",
      group_by: "user.id",
      columns: [
        { title: "id", data: "user.id" },
        { title: "Vorname", data: "user_address.firstname" },
        { title: "Nachname", data: "user_address.lastname" },
        { title: "Strasse", data: "user_address.street" },
        { title: "Stadt", data: "user_address.city" },
        { title: "PLZ", data: "user_address.zip" },
      ],
      options: [
        { type: "number", title: "id", data: "user.id" },
        { type: "text", title: "Email", data: "user.email" },
        { type: "text", title: "Telefon", data: "user.phone" },
        { type: "text", title: "Typ", data: "user.type" },
        { type: "date", title: "Geburtstag", data: "user.birthdate" },
        { type: "text", title: "Firma", data: "user_address.company" },
        { type: "text", title: "Vorname", data: "user_address.firstname" },
        { type: "text", title: "Nachname", data: "user_address.lastname" },
        { type: "text", title: "Anrede", data: "user_address.salutation" },
        { type: "text", title: "Titel", data: "user_address.title" },
        { type: "text", title: "Adresskommentar", data: "user_address.comment" },
        { type: "text", title: "Strasse", data: "user_address.street" },
        { type: "text", title: "Hausnummer", data: "user_address.housenumber" },
        { type: "text", title: "PLZ", data: "user_address.zip" },
        { type: "text", title: "Stadt", data: "user_address.city" },
        { type: "text", title: "Land", data: "user_address.country" },
        { type: "text", title: "Kontakt", data: "user_contact.content" },
        { type: "text", title: "Iban", data: "user_payment.iban" },
        { type: "text", title: "Konto", data: "user_payment.account" },
        { type: "text", title: "Branche", data: "user.branch" },
        { type: "text", title: "Mitarbeiteranzahl", data: "user.employee_amount" },
        { type: "text", title: "Abteilung", data: "user.section" },
        { type: "text", title: "Funktion", data: "user.role" },
        { type: "date", title: "Erstellt am", data: "user.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "number", title: "Fehlerhafte Logins", data: "user.failed_logins" },
        { type: "date", title: "Gesperrt seit", data: "user.locked_at" },
      ],
      resource: null,
    },
    admins: {
      title: "Mitarbeiter",
      type: "filter_admins",
      path: "/admins",
      select: "admin_user "
        + "left join admin_user_group on admin_user_group.id = admin_user.admin_user_group_id "
        + "left join client on client.id = admin_user.client_id "
        + "left join shop on shop.id = admin_user.shop_id "
        + "where admin_user_group.deleted_at is null",
      columns: [
        { title: "id", data: "admin_user.id" },
        { title: "Vorname", data: "admin_user.firstname" },
        { title: "Nachname", data: "admin_user.lastname" },
        { title: "Email", data: "admin_user.email" },
        { title: "Mandant", data: "client.name" },
        { title: "Shop", data: "shop.name" },
        { title: "Gruppe", data: "admin_user_group.name" },
      ],
      options: [
        { type: "number", title: "id", data: "admin_user.id" },
        { type: "text", title: "Vorname", data: "admin_user.firstname" },
        { type: "text", title: "Nachname", data: "admin_user.lastname" },
        { type: "text", title: "Email", data: "admin_user.email" },
        { type: "client", title: "Mandant", data: "client.name" },
        { type: "text", title: "Shop", data: "shop.name" },
        { type: "text", title: "Gruppe", data: "admin_user_group.name" },
        { type: "text", title: "Telefon", data: "admin_user.phone" },
        { type: "bool", title: "Bestätigt", data: "admin_user.approved" },
        { type: "date", title: "Erstellt am", data: "admin_user.created_at" },
        { type: "number", title: "Fehlerhafte Logins", data: "admin_user.failed_logins" },
        { type: "text", title: "Absendeadresse", data: "admin_user.email_from" },
        { type: "bool", title: "Superadmin", data: "admin_user.is_super_admin" },
      ],
      resource: null,
    },
    promotion_events: {
      title: "Aktionstermine",
      type: "filter_promotion_events",
      order: 0,
      order_dir: "desc",
      select: "event_date "
        + "left join client on client.id = event_date.client_id "
        + "where event_date.deleted_at is null",
      columns: [
        { title: "id", data: "event_date.id" },
        { title: "Datum", data: "event_date.date" },
        { title: "Veranstaltung", data: "event_date.name" },
        { title: "Mandant", data: "client.name" },
      ],
      options: [
        { type: "number", title: "id", data: "event_date.id" },
        { type: "event", title: "Veranstaltung", data: "event_date.event_id" },
        { type: "client", title: "Mandant", data: "event_date.client_id" },
        { type: "date", title: "Datum", data: "event_date.date" },
        { type: "weekday", title: "Wochentag", data: "WEEKDAY(event_date.date)" },
        { type: "month", title: "Monat", data: "MONTH(event_date.date)" },
      ],
      resource: null,
    },
    promotion_users: {
      title: "Aktionskunden",
      type: "filter_promotion_users",
      select: "user left join user_address on user_address.user_id = user.id left join memberships on memberships.user_id = user.id left join membership_types on memberships.membership_type_id = membership_types.id and memberships.deleted_at is null left join product as membership_product on membership_types.product_id = membership_product.id where user.deleted_at is null",
      group_by: "user.id",
      columns: [
        { title: "id", data: "user.id" },
        { title: "Vorname", data: "user_address.firstname" },
        { title: "Nachname", data: "user_address.lastname" },
        { title: "Stadt", data: "user_address.city" },
        { title: "PLZ", data: "user_address.zip" },
        { title: "Mitgliedschaften", data: "GROUP_CONCAT(membership_product.name SEPARATOR '\n')" },
      ],
      options: [
        { type: "number", title: "id", data: "user.id" },
        { type: "text", title: "Email", data: "user.email" },
        { type: "text", title: "Telefon", data: "user.phone" },
        { type: "memberships", title: "Mitgliedschaft", data: "membership_types.id" },
        { type: "text", title: "Typ", data: "user.type" },
        { type: "date", title: "Geburtstag", data: "user.birthdate" },
        { type: "text", title: "Firma", data: "user_address.company" },
        { type: "text", title: "Vorname", data: "user_address.firstname" },
        { type: "text", title: "Nachname", data: "user_address.lastname" },
        { type: "text", title: "Anrede", data: "user_address.salutation" },
        { type: "text", title: "Titel", data: "user_address.title" },
        { type: "text", title: "Adresskommentar", data: "user_address.comment" },
        { type: "text", title: "Strasse", data: "user_address.street" },
        { type: "text", title: "Hausnummer", data: "user_address.housenumber" },
        { type: "text", title: "PLZ", data: "user_address.zip" },
        { type: "text", title: "Stadt", data: "user_address.city" },
        { type: "text", title: "Land", data: "user_address.country" },
        { type: "text", title: "Branche", data: "user.branch" },
        { type: "text", title: "Mitarbeiteranzahl", data: "user.employee_amount" },
        { type: "text", title: "Abteilung", data: "user.section" },
        { type: "text", title: "Funktion", data: "user.role" },
      ],
      resource: null,
    },
    shop_events: {
      title: "Shops",
      type: "filter_shop_events",
      order: 0,
      order_dir: "desc",
      select: "event_date "
        + "left join client on client.id = event_date.client_id "
        + "where event_date.deleted_at is null",
      columns: [
        { title: "id", data: "event_date.id" },
        { title: "Datum", data: "event_date.date" },
        { title: "Veranstaltung", data: "event_date.name" },
        { title: "Mandant", data: "client.name" },
      ],
      options: [
        { type: "number", title: "id", data: "event_date.id" },
        { type: "event", title: "Veranstaltung", data: "event_date.event_id" },
        { type: "client", title: "Mandant", data: "event_date.client_id" },
        { type: "date", title: "Datum", data: "event_date.date" },
        { type: "weekday", title: "Wochentag", data: "WEEKDAY(event_date.date)" },
        { type: "month", title: "Monat", data: "MONTH(event_date.date)" },
      ],
      resource: null,
    },
    shop_users: {
      title: "Kunden",
      type: "filter_shop_users",
      select: "user left join user_address on user_address.user_id = user.id left join user_payment on user_payment.user_id = user.id left join user_contact on user_contact.user_id = user.id left join admin_user on admin_user.id = user.created_from where user.deleted_at is null",
      group_by: "user.id",
      columns: [
        { title: "id", data: "user.id" },
        { title: "Vorname", data: "user_address.firstname" },
        { title: "Nachname", data: "user_address.lastname" },
        { title: "Strasse", data: "user_address.street" },
        { title: "Stadt", data: "user_address.city" },
        { title: "PLZ", data: "user_address.zip" },
      ],
      options: [
        { type: "number", title: "id", data: "user.id" },
        { type: "text", title: "Email", data: "user.email" },
        { type: "text", title: "Telefon", data: "user.phone" },
        { type: "text", title: "Typ", data: "user.type" },
        { type: "date", title: "Geburtstag", data: "user.birthdate" },
        { type: "text", title: "Firma", data: "user_address.company" },
        { type: "text", title: "Vorname", data: "user_address.firstname" },
        { type: "text", title: "Nachname", data: "user_address.lastname" },
        { type: "text", title: "Anrede", data: "user_address.salutation" },
        { type: "text", title: "Titel", data: "user_address.title" },
        { type: "text", title: "Adresskommentar", data: "user_address.comment" },
        { type: "text", title: "Strasse", data: "user_address.street" },
        { type: "text", title: "Hausnummer", data: "user_address.housenumber" },
        { type: "text", title: "PLZ", data: "user_address.zip" },
        { type: "text", title: "Stadt", data: "user_address.city" },
        { type: "text", title: "Land", data: "user_address.country" },
        { type: "text", title: "Kontakt", data: "user_contact.content" },
        { type: "text", title: "Iban", data: "user_payment.iban" },
        { type: "text", title: "Konto", data: "user_payment.account" },
        { type: "text", title: "Branche", data: "user.branch" },
        { type: "text", title: "Mitarbeiteranzahl", data: "user.employee_amount" },
        { type: "text", title: "Abteilung", data: "user.section" },
        { type: "text", title: "Funktion", data: "user.role" },
        { type: "date", title: "Erstellt am", data: "user.created_at" },
        { type: "text", title: "Erstellt von", data: "admin_user.email" },
        { type: "number", title: "Fehlerhafte Logins", data: "user.failed_logins" },
        { type: "date", title: "Gesperrt seit", data: "user.locked_at" },
      ],
      resource: null,
    },
    product_descriptions: {
      title: "Produktbeschreibungen",
      type: "filter_product_descriptions",
      options: [
        { type: "date", title: "Datum", data: "event_date.date" },
        { type: "weekday", title: "Wochentag", data: "WEEKDAY(event_date.date)" },
        { type: "time", title: "Uhrzeit", data: "CAST(event_date.date as time)" },
      ],
      resource: null,
    },
    surveys: {
      type: "filter_surveys",
      select: "survey "
          + "where survey.deleted_at is null",
      options: [
        { type: "text", title: "Name", column: "survey.name" },
        { type: "text", title: "Beschreibung", column: "survey.description" },
        { type: "text", title: "Info", column: "survey.info" },
        { type: "bool", title: "Aktiv", column: "survey.active" },
      ],
      resource: null,
    },
    newsletters: {
      type: "filter_newsletters",
      select: "newsletter "
          + "left join client on client.id = newsletter.client_id "
          + "where newsletter.deleted_at is null",
      options: [
        { type: "text", title: "Titel", column: "newsletter.title" },
        { type: "text", title: "Inhalt", column: "newsletter.content" },
        { type: "client", title: "Mandant", column: "newsletter.client_id" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "newsletter.client_id", value: clientId.value },
      ],
      resource: null,
    },
    tasks: {
      type: "filter_tasks",
      select: "task "
          + "left join client on client.id = task.client_id "
          + "where task.deleted_at is null",
      options: [
        { type: "text", title: "Name", column: "task.name" },
        { type: "client", title: "Mandant", column: "task.client_id" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "task.client_id", value: clientId.value },
      ],
      resource: null,
    },

    emailTickets: {
      type: "filter_emails",
      select: `
    support_ticket 
    left join user_address on user_address.user_id = support_ticket.user_id 
    left join support_category on support_category.id = support_ticket.support_category_id
    left join support_priority_enum on support_priority_enum.id = support_ticket.ticket_priority_id
    left join support_status_enum on support_status_enum.id = support_ticket.ticket_status_id
    where support_ticket.deleted_at is null
  `,
      options: [
        { type: "client", title: "Mandant", column: "support_ticket.client_id" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "support_ticket.client_id", value: clientId.value },
        {
          type: "select",
          title: "Status",
          data: "support_ticket.ticket_status_id",
          value: 1, // Default to show all statuses
          options: [
            { id: 1, title: "Offen" },
            { id: 2, title: "in Bearbeitung" },
            { id: 3, title: "Warten auf Antwort" },
            { id: 4, title: "Zurückgestellt" },
            { id: 5, title: "Verschoben" },
            { id: 6, title: "Abgelehnt" },
            { id: 7, title: "Gelöst" },
            { id: 8, title: "Wieder geöffnet" },
            { id: 9, title: "Abgeschlossen" },
          ],
        },
      ],

      resource: null,
    },

    emailTemplates: {
      type: "email_templates",
      select: `
  support_ticket_email_template where support_ticket.deleted_at is null
`,
      options: [
        { type: "client", title: "Mandant", column: "support_ticket_email_template.client_id" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "support_ticket_email_template.client_id", value: clientId.value },
      ],

      resource: null,
    },
    skategorie: {
      type: "skategorie",
      select: `
  support_category where support_category.deleted_at is null
`,
      options: [
        { type: "client", title: "Mandant", column: "support_category.client_id" },
      ],
      standard_options: [
        { type: "client", title: "Mandant", data: "support_category.client_id", value: clientId.value },
      ],

      resource: null,
    },
  };
});
