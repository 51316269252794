export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/tinymce`,
  async getSnippets() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },

  async deleteSnippet(id) {
    return await $fetch(`${this.apiURL}/delete/${id}`, { headers: { Authorization: useToken().value }, method: "DELETE" });
  },
  async saveSnippet(body) {
    return await $fetch(this.apiURL, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async getSnippedCategories() {
    return await $fetch(`${this.apiURL}/category`, { headers: { Authorization: useToken().value } });
  },
});
