import { useToken } from "~/composables/admin";

export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/printer`,
  async printPDFPreview(client, printerId) {
    return await $fetch(`${this.apiURL}/print/preview/${client.id}/${printerId}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async printer(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async printers() {
    return await $fetch(`${this.apiURL}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async printersByClient(client) {
    return await $fetch(`${this.apiURL}/client/${client.id}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async printerHasClient(printer, client) {
    return await $fetch(`${this.apiURL}/${printer}/client/${client}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async printersByAdminUser(adminUser) {
    return await $fetch(`${this.apiURL}/admin/${adminUser.id}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async allPrintersByAdminUser(adminUser) {
    return await $fetch(`${this.apiURL}/admin/${adminUser.id}/all`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async typesAllWithUnusedPrinters(adminUser) {
    return await $fetch(`${this.apiURL}/admin/${adminUser.id}/all-types-with-unused-printer`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async addPrinterToAdmin(adminUser, printerId) {
    return await $fetch(`${this.apiURL}/admin/${adminUser.id}/add-printer`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { printer_id: printerId },
    });
  },
  async deletePrinterFromAdmin(adminUser, printerId) {
    return await $fetch(`${this.apiURL}/admin/${adminUser.id}/delete-printer`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { printer_id: printerId },
    });
  },
  async allTypes() {
    return await $fetch(`${this.apiURL}/type-all`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async allTypesWithPrinters() {
    return await $fetch(`${this.apiURL}/type-all-with-printers`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async allStatuses() {
    return await $fetch(`${this.apiURL}/status-all`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async savePrinter(printer) {
    return await $fetch(`${this.apiURL}/${printer.id}/save`, {
      method: "PUT",
      headers: { Authorization: useToken().value },
      body: { printer },
    });
  },
});
