export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/emails`,
  async sendRegisterMail(user) {
    return await $fetch(`${this.apiURL}/send-register`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: user.id },
    });
  },
  async sendPasswordMail(user) {
    return await $fetch(`${this.apiURL}/send-password`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: user.id },
    });
  },
  async sendAdminPasswordReset(email) {
    return await $fetch(`${this.apiURL}/send-admin-password-reset`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { email },
    });
  },
  async sendMail(sender, to, subject, body, type, clientId, orderId, userId, attachements = []) {
    const formData = new FormData();
    formData.append("sender", sender);
    formData.append("to[]", to);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("type", type);
    formData.append("client_id", clientId);
    formData.append("order_id", orderId);
    formData.append("user_id", userId);
    formData.append("has_custom_attachements", true);

    attachements.forEach((file) => {
      if (file instanceof File) formData.append("uploaded_attachements[]", file);
      else formData.append("attachements[]", JSON.stringify(file));
    });

    return await $fetch(`${this.apiURL}/send-mail`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: formData,
    });
  },

});
