export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/surveys`,
  async getSurveys() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getSurvey(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveSurvey(survey) {
    return await $fetch(this.apiURL, {
      method: survey.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...survey },
    });
  },
  async deleteSurvey(survey) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: survey.id },
    });
  },
  async getQuestions(surveyId) {
    return await $fetch(`${this.apiURL}/${surveyId}/questions`, { headers: { Authorization: useToken().value } });
  },
  async saveQuestion(question, surveyId) {
    return await $fetch(`${this.apiURL}/${surveyId}/save-question`, {
      method: question.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...question },
    });
  },
  async removeQuestion(questionId, surveyId) {
    return await $fetch(`${this.apiURL}/${surveyId}/remove-question`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: questionId },
    });
  },
  async saveAnswer(answer, questionId) {
    return await $fetch(`${this.apiURL}/${questionId}/save-answer`, {
      method: answer.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...answer },
    });
  },
  async getUserAnswers(surveyId) {
    return await $fetch(`${this.apiURL}/${surveyId}/get-user-answers`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async removeAnswer(answerId, questionId) {
    return await $fetch(`${this.apiURL}/${questionId}/remove-answer`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: answerId },
    });
  },
});
