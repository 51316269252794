export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/custom-dashboard`,

  async getHighCharts(clientId, adminUserId, dashboardId = null) {
    return await $fetch(`${this.apiURL}/get-highcharts`, {
      headers: { Authorization: useToken().value },
      method: "POST",
      body: { adminUserId, clientId, dashboardId },
    });
  },
  async getSingleChart(id) {
    return await $fetch(`${this.apiURL}/get-single-chart/${id}`, {
      headers: { Authorization: useToken().value },
      method: "GET",
    });
  },
  async getDashboardsByAdminId(adminUserId, filterId) {
    const body = { adminUserId, filterId };
    return await $fetch(`${this.apiURL}/dashboards-by-admin-id`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },

  async doAdminOwnsAnyDashboard(adminUserId) {
    return await $fetch(`${this.apiURL}/do-admin-owns-any-dashboard`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { adminUserId },
    });
  },

  async getDashboardsByFilterId(filterId, adminUserId) {
    return await $fetch(`${this.apiURL}/get-dashboards-by-filter-id`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filterId, adminUserId },
    });
  },

  async updateChartPosition(firstEl, secondEl) {
    const body = { firstEl, secondEl };
    return await $fetch(`${this.apiURL}/sort-chart`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async deleteChart(id) {
    return await $fetch(`${this.apiURL}/delete-chart/${id}`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
    });
  },
  async saveOrUpdateDashboard(body) {
    return await $fetch(`${this.apiURL}/save-dashboard`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async deleteDashboard(id) {
    return await $fetch(`${this.apiURL}/delete-dashboard/${id}`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
    });
  },

  async saveOrUpdateChart(body) {
    return await $fetch(`${this.apiURL}/save-highchart`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async getDashboardTypes() {
    return await $fetch(`${this.apiURL}/dashboard-types`, {
      headers: { Authorization: useToken().value },
    });
  },

  async getDashboards(adminId) {
    return await $fetch(`${this.apiURL}/get-dashboards/${adminId}`, {
      headers: { Authorization: useToken().value },
    });
  },
});
