import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreadcrump, LazyButton, LazyButtonCircle, LazyButtonMore, LazyChat, LazyCountdown, LazyForm, LazyHeadline, LazyIcon, LazyImage, LazyInput, LazyInputButton, LazyLinkAction, LazyNumberButton, LazyNumberControl, LazyPanel, LazySelect, LazySelectList, LazyShortcut, LazySwitch, LazyTable, LazyText, LazyTextCollapse, LazyTextarea, LazyTimer, LazyTinyMceEditor, LazyNuxtScrollbar } from '#components'
const lazyGlobalComponents = [
  ["Breadcrump", LazyBreadcrump],
["Button", LazyButton],
["ButtonCircle", LazyButtonCircle],
["ButtonMore", LazyButtonMore],
["Chat", LazyChat],
["Countdown", LazyCountdown],
["Form", LazyForm],
["Headline", LazyHeadline],
["Icon", LazyIcon],
["Image", LazyImage],
["Input", LazyInput],
["InputButton", LazyInputButton],
["LinkAction", LazyLinkAction],
["NumberButton", LazyNumberButton],
["NumberControl", LazyNumberControl],
["Panel", LazyPanel],
["Select", LazySelect],
["SelectList", LazySelectList],
["Shortcut", LazyShortcut],
["Switch", LazySwitch],
["Table", LazyTable],
["Text", LazyText],
["TextCollapse", LazyTextCollapse],
["Textarea", LazyTextarea],
["Timer", LazyTimer],
["TinyMceEditor", LazyTinyMceEditor],
["NuxtScrollbar", LazyNuxtScrollbar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
