export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/event-dates`,
  async getEventDates(filterId) {
    return await $fetch(`${this.apiURL}/get-all`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filter_id: filterId },
    });
  },
  async getNextEventDate(clientID) {
    return await $fetch(`${this.apiURL}/next`, {
      headers: { Authorization: useToken().value },
      params: { client_id: clientID },
    });
  },
  async getEventDate(id, shopId) {
    return await $fetch(`${this.apiURL}/${id}`, {
      headers: { Authorization: useToken().value },
      params: { shop_id: shopId },
    });
  },
  async getRoomStatistics(id, data) {
    return await $fetch(`${this.apiURL}/${id}/room-statistics`, {
      headers: { Authorization: useToken().value },
      params: data,
    });
  },
  async getRoomBook(id) {
    return await $fetch(`${this.apiURL}/${id}/room-book`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getTickets(eventDateId) {
    return await $fetch(`${this.apiURL}/${eventDateId}/tickets`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getRooms(eventDateId) {
    return await $fetch(`${this.apiURL}/${eventDateId}/rooms`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getTicket(eventDateId, eventDateProductId, productId, shopId, date) {
    return await $fetch(`${this.apiURL}/${eventDateId}/tickets/${eventDateProductId}`, {
      headers: { Authorization: useToken().value },
      params: { shop_id: shopId, request_date: date, product_id: productId },
    });
  },
  async getTicketsOfArray(eventDateIds) {
    return await $fetch(`${this.apiURL}/tickets`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { event_date_ids: eventDateIds },
    });
  },
  async getUpgradesOfArray(eventDateIds) {
    return await $fetch(`${this.apiURL}/upgrades`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { event_date_ids: eventDateIds },
    });
  },
  async getUpgrades(eventDateId) {
    return await $fetch(`${this.apiURL}/${eventDateId}/upgrades`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getEventDateShops(id) {
    return await $fetch(`${this.apiURL}/${id}/shops`, { headers: { Authorization: useToken().value } });
  },
  async saveEventDate(eventDate, standardProduct) {
    return await $fetch(this.apiURL, {
      method: eventDate.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...eventDate, standard_product: standardProduct },
    });
  },
  async deleteEventDate(eventDate) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: eventDate.id },
    });
  },
  async saveInternComment(_id, _comment) {
    return await $fetch(`${this.apiURL}/${_id}/save-intern-comment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { comment: _comment },
    });
  },
  async cancelEventDate(eventDate) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/cancel`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async recancelEventDate(eventDate) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/recancel`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async saveSort(eventDate, sort, isTicket) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/save-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { sort, is_ticket: isTicket },
    });
  },
  async addProduct(eventDate, product) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/add-product`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product },
    });
  },
  async saveProduct(eventDate, product) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/save-product`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...product },
    });
  },
  async removeProduct(eventDate, productId) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/remove-product`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { product_id: productId },
    });
  },
  async saveRoomVariant(eventDate, roomVariant) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/save-room-variant`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: roomVariant,
    });
  },
  async removeRoomVariant(eventDate, id) {
    return await $fetch(`${this.apiURL}/${eventDate.id}/remove-room-variant`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id },
    });
  },
  async blockSeats(eventDateId, objects, planVariantId, orderId, productId, eventDateProductId) {
    return await $fetch(`${this.apiURL}/${eventDateId}/block-seats`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { objects, plan_variant_id: planVariantId, order_id: orderId, product_id: productId, event_date_product_id: eventDateProductId },
    });
  },
  async blockKontingent(eventDateId, objects, planVariantId, userId, isContingent) {
    return await $fetch(`${this.apiURL}/${eventDateId}/block-kontingent`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { objects, plan_variant_id: planVariantId, user_id: userId, is_contingent: isContingent },
    });
  },
  async freeSeats(eventDateId, objectIds) {
    return await $fetch(`${this.apiURL}/${eventDateId}/free-seats`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { object_ids: objectIds },
    });
  },
  async freeKontingent(eventDateId, objectIds) {
    return await $fetch(`${this.apiURL}/${eventDateId}/free-kontingent`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { object_ids: objectIds },
    });
  },
  async copy(eventDateId, dates) {
    return await $fetch(`${this.apiURL}/${eventDateId}/copy`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { dates },
    });
  },
  async updateList(target, attr, value, eventDateIds, data) {
    return await $fetch(`${this.apiURL}/update-list`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { target, attr, value, event_date_ids: eventDateIds, data },
    });
  },
});
