export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/config`,
  async getConfig() {
    return await $fetch(`${this.apiURL}`, { headers: { Authorization: useToken().value } });
  },
  async saveConfig(config) {
    return await $fetch(`${this.apiURL}`, {
      method: "PUT",
      headers: { Authorization: useToken().value },
      body: { config },
    });
  },
  async saveFile(config, file) {
    const formData = new FormData();
    formData.append("id", config.id);
    formData.append("file", file);

    return await $fetch(`${this.apiURL}/saveFile`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
});
