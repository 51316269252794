export default defineNuxtPlugin(async (nuxtApp) => {
  const userPatterns = {
    email: { id: "email", name: "Email*", target: "user" },
    salutation: { id: "salutation", name: "Anrede (Herr, Frau)*", target: "user_address" },
    phone: { id: "phone", name: "Telefon", target: "user" },
    firstname: { id: "firstname", name: "Vorname", target: "user_address" },
    lastname: { id: "lastname", name: "Nachname", target: "user_address" },
    birthdate: { id: "birthdate", name: "Geburtstag", target: "user" },
    company: { id: "company", name: "Firma", target: "user_address" },
    title: { id: "title", name: "Titel", target: "user_address" },
    street: { id: "street", name: "Strasse", target: "user_address" },
    housenumber: { id: "housenumber", name: "Hausnummer", target: "user_address" },
    zip: { id: "zip", name: "PLZ", target: "user_address" },
    city: { id: "city", name: "Stadt", target: "user_address" },
    country: { id: "country", name: "Land", target: "user_address" },
    address_comment: { id: "comment", name: "Adresszusatz", target: "user_address" },
    comment: { id: "comment", name: "Kommentar", target: "user" },
    type: { id: "type", name: "Typ", target: "user" },
    branch: { id: "branch", name: "Branche", target: "user" },
    employee_amount: { id: "employee_amount", name: "Mitarbeiteranzahl", target: "user" },
    section: { id: "section", name: "Abteilung", target: "user" },
    role: { id: "role", name: "Funktion", target: "user" },
    user_statistics: { id: "user_statistics", name: "Andere Daten", target: "user_attribute", tooltip: "Hier können Sie weitere Daten in folgendem Format hinzufügen (separiert durch |): besuche=1|spezialGutschein=apfel" },

  };
  const paymentPatterns = {
    user_id: { id: "user_id", name: "Email*", target: "user_payment" },
    client_id: { id: "client_id", name: "Client/Mandant Nummer*", target: "user_payment" },
    account: { id: "account", name: "Buchungskonto*", target: "user_payment" },
    owner: { id: "owner", name: "Besitzer", target: "user_payment" },
    number: { id: "number", name: "Nummer", target: "user_payment" },
    blz: { id: "blz", name: "BLZ", target: "user_payment" },
    iban: { id: "iban", name: "IBAN", target: "user_payment" },
    bic: { id: "bic", name: "BIC", target: "user_payment" },
    mandate: { id: "mandate", name: "Mandat", target: "user_payment" },
    is_debit: { id: "is_debit", name: "Ist Lastschrift", target: "user_payment" },
    valid_from: { id: "valid_from", name: "Gültig ab", target: "user_payment" },
    valid_until: { id: "valid_until", name: "Gültig bis", target: "user_payment" },
    is_active: { id: "is_active", name: "Ist aktiv", target: "user_payment" },
  };

  const addressPattern = {
    user_id: { id: "user_id", name: "Email*", target: "user_address" },
    street: { id: "street", name: "Straße*", target: "user_address" },
    zip: { id: "zip", name: "Postleitzahl*", target: "user_address" },
    city: { id: "city", name: "Stadt*", target: "user_address" },
    country: { id: "country", name: "Land*", target: "user_address" },
    housenumber: { id: "housenumber", name: "Hausnummer", target: "user_address" },
    primary: { id: "primary", name: "Hauptadresse", target: "user_address" },
    company: { id: "company", name: "Firma", target: "user_address" },
    title: { id: "title", name: "Titel", target: "user_address" },
    salutation: { id: "salutation", name: "Anrede ", target: "user_address" },
    firstname: { id: "firstname", name: "Vorname", target: "user_address" },
    lastname: { id: "lastname", name: "Nachname", target: "user_address" },
    comment: { id: "comment", name: "Kommentar", target: "user_address" },
  };

  const contactPattern = {
    user_id: { id: "user_id", name: "Email*", target: "user_contact" },
    type: { id: "type", name: "Kontakttyp (E-Mail, Telefon)*", target: "user_contact" },
    primary: { id: "primary", name: "Hauptkontakt", target: "user_contact" },
    content: { id: "content", name: "Inhalt*", target: "user_contact" },
    comment: { id: "comment", name: "Kommentar", target: "user_contact" },
  };

  const historyPattern = {
    user_id: { id: "user_id", name: "Email*", target: "user_history" },
    type: { id: "type", name: "Historie-Art (E-Mail, Telefon)*", target: "user_history" },
    title: { id: "title", name: "Titel", target: "user_history" },
    content: { id: "content", name: "Inhalt", target: "user_history" },
    created_at: { id: "created_at", name: "Erstellt am / CRM-Datum", target: "user_history" },
    target: { id: "target", name: "Ziel", target: "user_history" },
  };

  // Define the getImportPattern function
  const getImportPattern = (param) => {
    if (param === "users") {
      return userPatterns;
    }
    if (param === "payments") {
      return paymentPatterns;
    }
    if (param === "addresses") {
      return addressPattern;
    }
    if (param === "contacts") {
      return contactPattern;
    }
    if (param === "history") {
      return historyPattern;
    }
    return {}; // Return an empty object for other params
  };

  // Provide the function to the app
  nuxtApp.provide("getImportPattern", getImportPattern);
});
