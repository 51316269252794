export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/tasks`,
  async getTasks() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getTask(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getTaskMails(id) {
    return await $fetch(`${this.apiURL}/${id}/mails`, { headers: { Authorization: useToken().value } });
  },
  async saveTask(task, numberModification = null) {
    if (numberModification) {
      task.numberModification = numberModification;
    }
    return await $fetch(this.apiURL, {
      method: task.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...task },
    });
  },
  async deleteTask(task) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: task.id },
    });
  },
  async getUserHistoryBatch(batchToken) {
    return await $fetch(`${useRuntimeConfig().public.apiURL}/user-history/batchEmail/${batchToken}`, { headers: { Authorization: useToken().value } });
  },
  async getTaskEventTriggers() {
    return await $fetch(`${useRuntimeConfig().public.apiURL}/task-event-triggers`, { headers: { Authorization: useToken().value } });
  },
  async getTaskEventTrigger(id) {
    return await $fetch(`${useRuntimeConfig().public.apiURL}/task-event-triggers/${id}`, { headers: { Authorization: useToken().value } });
  },
  async sendTestMail(task, userId, orderId) {
    return await $fetch(`${this.apiURL}/send-test-mail`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: {
        id: task.id,
        userId,
        orderId,
      },
    });
  },
});
