export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/payments`,
  async getPayments() {
    return await $fetch(`${this.apiURL}`, { headers: { Authorization: useToken().value } });
  },
  async getPayment(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getOrderIndicesByClient(clientId) {
    return await $fetch(`${this.apiURL}/order-indices/${clientId}`, { headers: { Authorization: useToken().value } });
  },
  async savePayment(payment) {
    return await $fetch(this.apiURL, {
      method: payment.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...payment },
    });
  },
  async saveOrderIndex(orderIndex) {
    return await $fetch(`${this.apiURL}/order-indices`, {
      method: "PUT",
      headers: { Authorization: useToken().value },
      body: { ...orderIndex },
    });
  },
  async savePaymentsOrder(order) {
    return await $fetch(`${this.apiURL}/save-order`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order },
    });
  },
  async deletePayment(payment) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: payment.id },
    });
  },
});
